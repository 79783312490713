
import { settings } from "@/libraries/storage";
import { Component, Vue } from 'vue-property-decorator'
import { namespace } from "vuex-class";

const AppStore = namespace('AppStore')
const VrStore = namespace('VrStore')

@Component
export default class AppView extends Vue {
    @AppStore.Mutation
    setDisableScroll!: (status: boolean) => void

    @AppStore.State
    overlayUi!: boolean

    @VrStore.State
    longName!: string

    beforeMount(): void {
        this.setDisableScroll(false)
    }

    async logout(): Promise<void> {
        settings.del('auth', 'user', 'strategist')
        settings.del('auth', 'user', 'token')

        await this.$router.push('/login')
    }
}
